<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Minor Project: Technology Journey Map
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">Technology Journey Map</h2>
                      <p class="description mt--30">
                        <v-col md="12" sm="12" cols="12">
                          <div class="thumbnail">
                            <img
                              class="w-100"
                              src="../../assets/images/journey.png"
                              alt="Team Building"
                            />
                          </div>
                        </v-col>
                      </p>
                      <h3>Description</h3>
                      <p>
                        I choose to play Adventures with Anxiety, and this is an
                        interactive story about anxiety, where you are the
                        anxiety. I decided on this game because of my battle
                        with anxiety this past couple of years during the
                        pandemic, and I found it interesting that I would be on
                        the other side of it.
                      </p>
                      <h3>Map Features</h3>
                      <p>
                        I created my map based on the emotions I was
                        experiencing as I played through. I decided to go this
                        route because this is a game on anxiety, a feeling I am
                        familiar with, and if it is supposed to help in a way, I
                        think emotions as a measure over the time played can
                        paint the picture. The game is 30 minutes long, and I
                        based that on five emotions I felt. In the beginning, I
                        went in with a neutral feeling starting the game not
                        knowing what to expect. As the game began, I felt
                        anxious because it brought up questions and answers that
                        I had experienced, bringing my anxiety to flare up.
                        Next, the human starts to drink to ease the anxious
                        feelings they are having. This scenario made me angry
                        because I went through a similar situation using Alcohol
                        as an aid, but it only worsened. I was more so mad at
                        myself than the game. After that, the game has the
                        anxiety and the human talk to each other and communicate
                        about what is going on being a calming feeling. This had
                        me thinking about my experiences and how I could
                        communicate those or convince myself that my anxiety is
                        just trying to protect me. We need to come to an equal
                        ground of normalcy. After thinking about it, the game
                        ended and had me in a clear mind, not worried and
                        showing me we all have or had something happen and that
                        it's okay. I didn't think I would go through so many
                        emotions while playing a game like this, but I was
                        wrong. It helped me really apply what the game mentioned
                        to my own problems. I chose to communicate emotions
                        because it is not something I usually do, and I used
                        emojis instead of numbers as a visual way.
                      </p>
                      <h3>Reflection</h3>
                      <p>
                        The game is a battle between the human and the anxiety,
                        and you play as the anxiety. The interactive story is
                        interesting because it puts you as the anxiety, and you
                        are trying to protect the human. Still, this protection
                        is seen as fear by the humans, causing their anxiety to
                        start. You are trying to get the human energy bar to
                        zero by protecting their physical, social, and moral
                        needs. You are the inner voice telling the human the
                        worst-case scenarios of partaking in things like a
                        party. For example, you are invited to a party, and it
                        asks what you should do, the choices are Say yes, or
                        we'll die from loneliness, say no, it's full of
                        poisonous drugs, and ignore it, we make parties sad.
                        These are all bad and worrisome choices that relates
                        well with how anxiety attacks. Even if you select the
                        most positive answer, for example, you will attend it,
                        then go into details about how no one will show up to
                        your funeral if you die. So even though the outcome of
                        going to a party was positive, the anxiety goes right
                        into but if we died.
                      </p>
                      <p>
                        You answer these types of questions, which result in it
                        damaging the human and eventually taking their health to
                        zero. When the health gets to zero is says you have
                        successfully protected your human's physical, social,
                        and moral needs. After you successfully make the human
                        cry or throw the phone, you end up at the party for
                        round two. In the second round, the human tries to talk
                        about good things about themselves, and the anxiety
                        continues to steer the direction into a negative. A
                        person from the party then shows you how they could get
                        rid of that voice by drinking. The drinking ends up
                        damaging the anxiety's health. Right before the human is
                        about to beat the anxiety, it gives them a glance at the
                        consequences of drinking, making the anxiety win round
                        2. After round 2 and some talking to the person throwing
                        the party, you begin round 3. The conversation goes into
                        how people handle their anxiety by throwing themselves
                        into work or sex, drugs, and social media to ignore
                        their anxiety. You end up tieing in the third round
                        where the human will jump off the building into a pool,
                        and anxiety apologizes for not realizing it was hurting
                        the human. The human leaves the rooftop with the
                        understanding that they are scared but that it is okay
                        to be afraid of and then it goes into a cut scene of
                        conversation explaining how they went through all that
                        stuff and still survived. You then talk about fears by
                        communicating with the anxiety instead of fighting the
                        anxiety. It has you go through all the scenarios of fear
                        and talk them through with the human, causing relief.
                      </p>
                      <p>
                        Overall this game had my emotions going everywhere.
                        There were a lot of situations I could relate to, and
                        putting myself in the shoes of anxiety had me see a
                        different side of things. Anxiety is so obsessed with
                        trying to protect the human from being hurt, and it
                        didn't realize it was hurting the human. The wolf
                        realizes it doesn't need to be a guard dog and protect
                        you at all costs because we have all been through the
                        rough stuff. The outcome was communication and being
                        okay that it takes time to improve. I think the game did
                        a great job relating to a person with anxiety by putting
                        you on the other side of it.
                      </p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5020"
                          >Back to LDTE 5020</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
